/* Built In Imports */
/* External Imports */
import { Box, Heading, Image, Link, List, ListItem } from '@chakra-ui/react';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from 'react-share';

/* Internal Imports */
/* Components */
import config from '@config';
import { Likes } from '@msr/components/Utility/Likes';

/* Services */

/**
 * @param {string} url
 * @returns
 */
const SocialIcon = ({
  url,
  isLikeVisible = true,
  direction,
  position,
  data,
  likesCount,
  style = 'normal',
  display,
  shareTextColor,
  isMobile,
  hideInMobile,
  ...props
}) => {
  // useEffect(() => {
  //   document.addEventListener('scroll', () => {
  //     const scrollCheck = window.scrollY > window.innerHeight;
  //     if (scrollCheck !== scroll) {
  //       setScroll(scrollCheck);
  //     }
  //   });

  return (
    <List
      width={{ base: '70px', lg: ' 70px' }}
      //pos={{ base: 'static', lg: scroll ? 'absolute' : 'fixed' }}
      // pos={{ base: position || 'fixed', lg: 'fixed' }}
      pos={{ base: position || 'fixed', lg: position || 'fixed' }}
      zIndex="10"
      bottom={{ base: '10%' }}
      // top={{ base: '76%', lg: '60%' }}
      display={{
        base: hideInMobile ? 'none' : 'block',
        md: 'none',
        lg: 'grid',
      }}
      // display={display || { base: 'block', md: 'none', xl: 'grid' }}
      left={{ base: '13.38px', md: '1%', lg: '3%' }}
      justifyItems="center"
      alignItems="center"
      textAlign="center"
      className="social-icon-comp"
      {...props}
    >
      {isLikeVisible && <Likes data={data} likesCount={likesCount} />}
      <Box
        borderTop={{
          base: 'none',
          lg: isLikeVisible ? '1px solid #8F92C1' : 'none',
        }}
        borderRight={{
          // base: '1px solid #000',
          base: 'none',
          md: 'none',
        }}
        display={{ base: 'flex', lg: 'block' }}
        pb={{ base: '20px', lg: '20px' }}
        pt={{ base: '20px', lg: 0 }}
        mt={{ base: '0', lg: '20px' }}
        width={{ base: '20px', lg: 'full' }}
      />

      <Heading
        as="h6"
        color={'#8F92C1'}
        fontSize="18px"
        pl={{ base: '0', lg: 0 }}
        pb="10px"
      >
        Share
      </Heading>

      <ListItem
        w="40px"
        display={{ base: 'none', md: 'block' }}
        m={{ lg: '0', md: '10px 0' }}
      >
        <FacebookShareButton url={`${config.BASE_PATH}${url}`} quote={''}>
          <Link
            mb={{ base: 0, sm: 0, md: 0, lg: '10px' }}
            display="block"
            href={`https://www.addtoany.com/add_to/facebook?linkurl=${config.BASE_PATH}${url}`}
          >
            <Image
              w="28px"
              h="28px"
              alt="facebook icon"
              src={`${config.imgPath}/d/46272/1666383820-fb-msr.png`}
            />
          </Link>
        </FacebookShareButton>
      </ListItem>
      <ListItem
        w="40px"
        display={{ base: 'none', md: 'block' }}
        m={{ lg: '0', md: '10px 0' }}
      >
        <TwitterShareButton url={`${config.BASE_PATH}${url}`} title={''}>
          <Link
            mb={{ base: 0, sm: 0, md: 0, lg: '10px' }}
            display="block"
            href={`https://www.addtoany.com/add_to/twitter?linkurl=${config.BASE_PATH}${url}`}
          >
            <Image
              w="28px"
              h="28px"
              alt="twitter icon"
              src={`${config.imgPath}/d/46272/1666383825-twitter-msr.png`}
            />
          </Link>
        </TwitterShareButton>
      </ListItem>
      <ListItem w={{ base: 'auto', md: '40px' }} m={{ lg: '0', md: '10px 0' }}>
        <WhatsappShareButton
          url={`${config.BASE_PATH}${url}`}
          title={''}
          separator=":: "
        >
          <Link
            mb={{ base: 0, sm: 0, md: 0, lg: '10px' }}
            display="block"
            href={`https://www.addtoany.com/add_to/whatsapp?linkurl=${config.BASE_PATH}${url}`}
          >
            <Image
              w="30px"
              h="30px"
              alt="whatsapp icon"
              src={`${config.imgPath}/d/46272/1666383828-whatsapp-msr.png`}
            />
          </Link>
        </WhatsappShareButton>
      </ListItem>
      <style>
        {`
          @media (min-width: 768px) and (max-width: 1140px) {
          .social-icon-comp {
            display: none !important;
          }
          }
        `}
      </style>
    </List>
  );
};

export default SocialIcon;
