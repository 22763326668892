/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import { RegionContextProvider } from '@msr/store/region-context';

/* Services */

/**
 * Renders Wrap Layout Component
 *
 * @param {object} props - Child Elements
 * @returns {ReactElements} Wrap Layout Component
 */
const WrapLayout = props => {
  return (
    <Box
      className={`${
        props?.pageData?.pageConfig &&
        props.pageData.pageConfig[0]?.value?.msr_theme
      }-theme`}
      bgColor={
        props.bgColor ||
        (props?.pageData?.pageConfig &&
          props.pageData.pageConfig[0]?.value?.msr_theme === 'light')
          ? '#FFF7E9'
          : '#03062C'
      }
      float="left"
      w="100%"
      margin={props.margin}
      p={{ base: props.pad, lg: !isNaN(props.pad) ? props.pad : '0' }}
      // ? overflow="hidden" need to verify
    >
      <RegionContextProvider>{props.children}</RegionContextProvider>
    </Box>
  );
};
export default WrapLayout;
