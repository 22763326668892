/* Built In Imports */
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Text } from '@chakra-ui/react';
import { useCookies } from 'react-cookie';

/* Internal Imports */
/* Components */
import config from '@config';
import { numFormatter } from '@msr/components/Utility/utils';

/* Services */
import { postLikes } from 'services/msrService';

/**
 *
 * @param {Object} data
 * @returns
 */
export const Likes = ({ data, lang, likesCount, dir }) => {
  const envName = config.ENV_NAME === 'production' ? '' : config.ENV_NAME + '_';
  const likeCookieName = `${envName}msr_likes_id`;
  const [likeCount, setLikeCount] = useState(likesCount);
  const [activeBtn, setActiveBtn] = useState('none');
  const [likesCookie, setCookie, removeCookie] = useCookies([likeCookieName]);
  const [meLiked, setMeLiked] = useState(false);
  const likeCountLimit = 100;

  // consoleLog('likesCount', likesCount, data);

  useEffect(() => {
    if (likesCookie[likeCookieName]?.indexOf(data?.uuid) > -1) {
      setMeLiked(true);
    }
    // consoleLog('meLiked', meLiked);
    if (meLiked && likeCount === 0) {
      setLikeCount(1);
    }
  }, [meLiked]);

  const handleClick = () => {
    // consoleLog('handleClick called', data, likesCount);
    postLikeFromAPI();
  };

  const postLikeFromAPI = async () => { 
    const { contentType, uuid } = data;
    // consoleLog('postLikeFromAPI called', contentType, uuid);
    const response = await postLikes({
      uuid: uuid,
      action: meLiked ? 'remove' : 'add',
      contenttype: contentType,
      language: lang || 'en'
    });
    const responseBody = await response;
    setLikeCount(responseBody?.likestotalcount);
    setMeLiked(!meLiked);
    // consoleLog('cookieData', likesCookie[likeCookieName], meLiked, likeCount, responseBody);
    let cookieData = likesCookie[likeCookieName];
    if (cookieData?.length && meLiked) {
      const index = cookieData.indexOf(uuid);

      if (index > -1) {
        // only splice array when item is found
        cookieData = cookieData.splice(index, 1);
        // consoleLog('index', likesCookie, index, cookieData);
      }
    } else {
      if (!cookieData) {
        cookieData = [];
      }
      cookieData.push(uuid);
    }

    // consoleLog('setCookie', likeCookieName, meLiked);
    // document.cookie = `${likeCookieName}=${cookieData} Path=/; domain==${config.COOKIE_DOMAIN} Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    setCookie(likeCookieName, cookieData, {
      path: '/',
      maxAge: 3600 * 24 * 365, // Expires after 1year
      sameSite: true,
      domain: config.COOKIE_DOMAIN,
      secure: true
    });
  };

  return (
    <>
      <button
        className={`btn ${activeBtn === 'like' ? 'like-active' : ''}`}
        onClick={handleClick}
      >
        <Box display="flex" flexDirection={dir || "column"} alignItems="center">
          {meLiked ? (
            <svg
              width="22"
              height="23"
              viewBox="0 0 22 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.79167 20.9624V10.5331V20.9624ZM2 12.4293V19.0662C2 20.1135 2.85812 20.9624 3.91667 20.9624H16.7834C18.2025 20.9624 19.4093 19.9381 19.6251 18.5506L20.6571 11.9137C20.9251 10.1907 19.5776 8.63684 17.8156 8.63684H14.4583C13.929 8.63684 13.5 8.21235 13.5 7.68872V4.33791C13.5 3.04671 12.442 2 11.1369 2C10.8256 2 10.5435 2.18138 10.4171 2.4628L7.04461 9.96999C6.8908 10.3125 6.5476 10.5331 6.16887 10.5331H3.91667C2.85812 10.5331 2 11.382 2 12.4293Z"
                fill="#FFA735"
              />
              <path
                d="M6.79167 20.9624V10.5331M2 12.4293V19.0662C2 20.1135 2.85812 20.9624 3.91667 20.9624H16.7834C18.2025 20.9624 19.4093 19.9381 19.6251 18.5506L20.6571 11.9137C20.9251 10.1907 19.5776 8.63684 17.8156 8.63684H14.4583C13.929 8.63684 13.5 8.21235 13.5 7.68872V4.33791C13.5 3.04671 12.442 2 11.1369 2C10.8256 2 10.5435 2.18138 10.4171 2.4628L7.04461 9.96999C6.8908 10.3125 6.5476 10.5331 6.16887 10.5331H3.91667C2.85812 10.5331 2 11.382 2 12.4293Z"
                stroke="#8F92C1"
                strokeWidth="2.55556"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="22"
              height="23"
              viewBox="0 0 22 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.79167 21.541V10.9224M2 12.853V19.6104C2 20.6767 2.85812 21.541 3.91667 21.541H16.7834C18.2025 21.541 19.4093 20.4982 19.6251 19.0854L20.6571 12.3281C20.9251 10.5738 19.5776 8.99171 17.8156 8.99171H14.4583C13.929 8.99171 13.5 8.55951 13.5 8.02638V4.61473C13.5 3.30009 12.442 2.23438 11.1369 2.23438C10.8256 2.23438 10.5435 2.41904 10.4171 2.70557L7.04461 10.3491C6.8908 10.6977 6.5476 10.9224 6.16887 10.9224H3.91667C2.85812 10.9224 2 11.7867 2 12.853Z"
                stroke="#8F92C1"
                strokeWidth="2.55556"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          {likesCount > likeCountLimit && (
            <Text
              fontSize="16px"
              fontFamily="FedraSansStd-medium"
              ml={dir === 'row' ? '5px' : '0'}
            >
              {numFormatter(likeCount)}
            </Text>
          )}          
        </Box>
      </button>
    </>
  );
};
