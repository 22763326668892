/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Link
} from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import { ChevronRightIcon } from '@chakra-ui/icons';
import { LangStyle } from 'constants/index';

/* Services */

/**
 * Renders the Breadcrum component
 *
 * @param {string} page page name
 * @param {string} alsoin languages
 * @param {string} region country
 * @param {string} lang language
 * @returns {ReactElement} Breadcrum component
 */

const Breadcrum = ({ page, region, lang }) => {
  return page?.length > 0 && (
    <>
      <Box
        display={{ base: 'none', lg: 'flex' }}
        fontFamily="Fedra-Light"
        fontSize="13px"
        letterSpacing=".2px"
        color="#FFF7E9"
        pos="relative"
        w="100%"
        background="#1D1F22"
        p={{
          base: '3px 30px',
          lg: '3px calc((100% - 1134px) / 2) 5px calc((100% - 1134px) / 2)',
        }}
      >
        <Box
          float={
            LangStyle[lang]?.alignRTL ? LangStyle[lang]?.alignRTL : 'left'
          }
        >
          <Breadcrumb
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            p={{ md: '0 0 0 10px', lg: '0 0 0 21px' }}
            fontWeight="medium"
            fontSize="0.8rem"
            display={{ base: 'none', lg: 'block', md: 'block' }}
            separator={<ChevronRightIcon color="#FFF7E9" w="5" h="5" />}
          >
            {page?.map((data, index, { length }) => {
              let link = data?.link;
              return (
                <BreadcrumbItem key={index}>
                  {index + 1 < length && link && (
                    <NextLink href={link || '#'} passHref prefetch={false} legacyBehavior>
                      <Link cursor="pointer">
                        <BreadcrumbLink
                          _hover={{
                            color: '#FFF7E9',
                            textDecoration: 'underline',
                          }}
                          fontFamily="FedraSansStd-book"
                          p="0 2px"
                          fontSize="13px"
                          textTransform="capitalize"
                          pointerEvents="pointer"
                        >
                          {data?.title}
                        </BreadcrumbLink>
                      </Link>
                    </NextLink>
                  )}
                  {index + 1 === length && (
                    <BreadcrumbLink
                      color="#FFF7E9"
                      _hover={{ textDecoration: 'none' }}
                      fontFamily="FedraSansStd-book"
                      p="0 2px"
                      fontSize="13px"
                      textTransform="capitalize"
                      pointerEvents="none"
                    >
                      {data?.title}
                    </BreadcrumbLink>
                  )}
                </BreadcrumbItem>
              );
            })}
          </Breadcrumb>
        </Box>
      </Box>
    </>
  );
};

export default Breadcrum;
