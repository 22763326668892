/* Built In Imports */
import { useRouter } from 'next/router';

/* External Imports */
/* Internal Imports */
/* Components */
import SocialIcon from '@msr/components/Utility/SocialIcon';

/**
 * Renders the Style Config on sectionId
 * @param {Object} PageConfig- Data for section
 * @param {Object} children - Child Elements
 * @param {string} id - id
 * @returns {ReactElement} PageConfig for children component
 */
const PageConfig = ({
  pageData,
  isLikeVisible = false,
  direction = 'row',
  position,
  // top = '60%',
  likesCount = 0,
  shareTextColor,
  hideInMobile = false
}) => {
  const router = useRouter();
  const url = router.asPath;

  return (
    <>
      {pageData?.pageConfig?.length > 0 && pageData?.pageConfig?.map((config, i) => {
        if (!config?.value?.hasOwnProperty('msr_sticky_social') || (config?.value?.msr_sticky_social === true)) {
          return (
            <SocialIcon
              likesCount={likesCount}
              url={url}
              isLikeVisible={isLikeVisible}
              direction={direction}
              position={position}
              // top={top}
              data={pageData}
              shareTextColor={shareTextColor}
              hideInMobile={hideInMobile}
            />
          );
        }
      })}

      {!pageData?.pageConfig && (
        <SocialIcon
          likesCount={likesCount}
          url={url}
          isLikeVisible={isLikeVisible}
          direction={direction}
          position={position}
          // top={top}
          data={pageData}
          shareTextColor={shareTextColor}
          hideInMobile={hideInMobile}
        />
      )}
    </>
  );
};

export default PageConfig;
